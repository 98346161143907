import React, { useEffect } from 'react';
import Header from './HeaderUpdated';
import Footer from './Footer';
import FooterCTA from './FooterCTA';
import NewsBanner from './NewsBanner';
import CookieConsent from './CookieConsent';

const Layout = ({ children }) => {
  // Scroll to
  useEffect(() => {
    if (typeof window == 'undefined') return false;

    // Delay hubspot loading
    window.hsConversationsSettings = {
      loadImmediately: false,
    };

    if (window.location.hash) {
      setTimeout(() => {
        const el = document.querySelector(window.location.hash);
        if (!el || window.scrollY > 1) return;
        window.scrollTo({
          top: el.getBoundingClientRect().top,
          behavior: 'smooth',
        });
      }, 1000);
    }
  }, [children]);

  return (
    <>
      <NewsBanner />
      <div>
        <Header />
        {children}
        <FooterCTA padding="lg" />
        <Footer />
        <CookieConsent />
      </div>
    </>
  );
};

export default Layout;
