import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from './Link';
import styled from 'styled-components';
import tw from 'twin.macro';
import media from '../utils/media';

const ButtonContainer = styled.div`
  ${tw`align-top mx-auto`}
  max-width: 327px;
`;

export default (props) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        options {
          options {
            footer {
              cta {
                heading
                button {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const { heading, button } = wp.options.options.footer.cta;

  const Section = styled.section`
    ${tw`bg-d-black text-white text-center`}
    padding: 4rem 0 4.5rem 0;
    ${media.greaterThan('md')`
      padding: ${() => (props.padding === 'lg' ? '97px 0 115px 0' : '80px 0')}
    `}
  `;

  return (
    <Section>
      <div className="container">
        <h3 className="text-42px mb-8">{heading}</h3>
        <ButtonContainer>
          <Link to={button.url} className="button button--h57 button--waves button--white w-full">
            {button.title}
          </Link>
        </ButtonContainer>
      </div>
    </Section>
  );
};
