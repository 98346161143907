import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';

const Container = styled.div`
  .CookieConsent {
    position: fixed;
    left: 1rem;
    bottom: 0;
    transform: translateY(-1rem);
    z-index: 100000;
    background-color: #fff;
    background-color: #000;
    color: #fff;

    max-width: calc(100% - 300px);
    padding: 0.8rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.04);

    @media screen and (max-width: 515px) {
      max-width: calc(100% - 2rem);
    }

    > :first-child {
      flex: 1 1 auto;
      font-size: 14px;
      max-width: 230px;

      font-weight: 500;
      text-align: left;
      margin-bottom: 1em;
    }

    > :first-child,
    > :last-child {
      padding: 0 1em;
      @media screen and (max-width: 515px) {
        max-width: 100%;
        width: 100%;
      }
    }

    button {
      flex: 0 1 auto;
      min-width: 0;
      font-size: 14px;
      padding-right: 69px;
      @media screen and (max-width: 515px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;

export default () => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        options {
          options {
            cookieNotice {
              heading
              button
            }
          }
        }
      }
    }
  `);

  const { heading, button } = wp.options.options.cookieNotice;

  return (
    <Container>
      <CookieConsent location="bottom" buttonText={button || `Accept Cookies`} buttonClasses={'button button--white'} acceptOnScroll={true} disableStyles debug={false} cookieName="cookieconsent" expires={150}>
        <div dangerouslySetInnerHTML={{ __html: heading }}   />
      </CookieConsent>
    </Container>
  );
};
