import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CloseSVG from '../assets/images/close-banner.svg';
import Cookies from 'js-cookie';
import slugify from 'slugify';

const NewsBanner = () => {
  const data = useStaticQuery(graphql`
      {
        wp {
          options {
            options {
              newsBannerGroup {
                isShown
                newsBanner {
                  title
                  url
                  target
                }
              }
            }
          }
        }
      }
    `);

  const { isShown, newsBanner } = data.wp.options.options.newsBannerGroup;
  const isBrowser = typeof window !== 'undefined';


  const [bannerShown, setBannerShown] = useState(false);

  useEffect(()=>{
    // let initialState = ;

    if (isBrowser) {
      setBannerShown(isShown === true && newsBanner.title.length > 0 && Cookies.get('hidebanner') !== slugify(newsBanner.title));
    }
  },[]);




  const hideBanner = () => {
    Cookies.set('hidebanner', slugify(newsBanner.title), { expires: 1 });
    setBannerShown(false);
  };

  return bannerShown ? (
    <div className="hidden lg:flex w-full bg-red h-12 bg-purple text-white font-medium text-16px justify-center items-center relative">
      <a href={newsBanner.url} target={newsBanner.target} className="text-center" dangerouslySetInnerHTML={{ __html: newsBanner.title }} />
      <div className="absolute p-2 right-0 mr-12 cursor-pointer" onClick={hideBanner}>
        <img src={CloseSVG} alt="close" />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default NewsBanner;
